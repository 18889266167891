@import 'bootstrap/bootstrap';

/*--------------------------------------------------------------
# Alert
--------------------------------------------------------------*/
.alert {
  margin-bottom: 0;
  text-align: center;
  border-radius: 0;
}

.alert > a {
  text-decoration: underline;
  &:hover {
    text-decoration: underline;
  }
}

/*--------------------------------------------------------------
# Navbar
--------------------------------------------------------------*/

.navbar {
  padding: 1.5rem 1rem;
}

/* brand and text color */
.navbar-light .navbar-brand,
.navbar-light .navbar-text {
    color: rgba(71, 71, 71, 0.84);
}

.navbar-light .navbar-nav .nav-item {
    padding: 8px 0 8px 20px;
}

/* link color */
.navbar-light .navbar-nav .nav-link {
    color: rgba(71, 71, 71, 0.84);
    border-bottom: 2px solid #fff;
    font-size: 20px;
    padding: 5px 2px;
}

/* active and hovered link color */
.navbar-light .navbar-nav .active > .nav-link,
.navbar-light .navbar-nav .nav-item .nav-link:hover{
    color: #9ba158;
    border-color: #9ba158;
    transition: all 0.5s;
}

/* hamburger menu border */
.navbar-toggler {
    border: none;
}

/* afspraak button */
.afspraak-btn {
  background: #9ba158;
  color: #fff;
  border-radius: 50px;
  padding: 8px 25px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

.afspraak-btn:hover {
  background: #747941;
  color: #fff;
  text-decoration: none;
}

.jumbotron {
  padding: 1rem;
  min-height: 65vh;
  background: linear-gradient(0deg, #00000088 10%, #ffffff44 70%), url(/assets/img/bg.jpg) top center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 0;
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  border-radius: 0;
}

.lead {
  color: white;
}

.lead a {
  color: white;
  text-decoration: underline;
  &:hover {
    color: #9ba158;
    text-decoration: underline;
  }
}

.afspraak-btn-lg {
  background: #9ba158;
  color: #fff;
  border-radius: 50px;
  padding: 20px 30px;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

.afspraak-btn-lg:hover {
  background: #747941;
  color: #fff;
  text-decoration: none;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  margin-bottom: 140px;
}

header {
  padding: 0.4rem;
}

@media (max-width: 990px) {
  .text-center-mobile {
    text-align: center;
  }
}

footer {
  position: absolute;
  width: 100%;
  bottom: 0;
  padding: 0.4rem;
}

a:hover {
  text-decoration: none;
}

small a {
  color: rgba(71, 71, 71, 0.64);
  text-decoration: underline;
  &:hover {
    color: #9ba158;
    text-decoration: underline;
  }
}

.post-title {
  color: rgba(71, 71, 71, 0.64);
  &:hover {
    color: #9ba158;
  }
}

.post-date {
    text-transform: uppercase;
    font-size: 0.8rem;
}

.post-link a {
  color: rgba(71, 71, 71, 0.64);
  &:hover {
    color: #9ba158;
  }
}

h4 {
  color: #9ba158;
  font-weight: bold;
}

@media (min-width: 990px) {
  .border-right-lg {
    border-right: 1px solid #dee2e6;
  }
}

.pagination > li > a {
    background-color: white;
    color: #9ba158;
}

.pagination > li > a:focus,
.pagination > li > a:hover,
.pagination > li > span:focus,
.pagination > li > span:hover {
    color: #5a5a5a;
    background-color: #eee;
    border-color: #ddd;
}

.pagination > .active > a {
    color: white;
    background-color: #9ba158 !important;
    border: solid 1px #9ba158 !important;
}

.pagination > .active > a:hover {
    background-color: #9ba158 !important;
    border: solid 1px #9ba158;
}

.nuttige-link a {
  color: rgba(71, 71, 71, 1);
  font-weight: 600;
  &:hover {
    color: #9ba158;
  }
}